

import Vue from 'vue';
import {Component, Prop, Watch} from "vue-property-decorator";
// @ts-ignore
import Switches from 'vue-switches';
// @ts-ignore
import VueQRCodeComponent from 'vue-qrcode-component';
import {mixin as Clickaway} from "vue-clickaway";
import routerBase from '@/helpers/routerBase';

@Component({
  components: {
    qrCode: VueQRCodeComponent,
    Switches
  },
  mixins: [Clickaway],
})
export default class NevronModuleQr extends Vue {
  @Prop()
  moduleId: any;

  @Prop({default: null})
  guestFlowModule: any;

  @Prop()
  type: any;

  size: any = 400;

  moduleQr: any = null

  urlContent: any = null
  qrAttributes: any = [];


  created() {
    this.qrAttributes = {
      mlAppDownload: {
        moduleId: this.moduleId,
        key: 'ml_app_download',
        value: false,
        queryText: 'app_download',
        type: this.type
      },
      mlAppleAppIdentifier: {
        moduleId: this.moduleId,
        key: 'ml_apple_app_identifier',
        value: '',
        queryText: 'apple_app_identifier',
        type: this.type
      },
      mlAndriodAppIdentifier: {
        moduleId: this.moduleId,
        key: 'ml_andriod_app_identifier',
        value: '',
        queryText: 'android_app_identifier',
        type: this.type
      },
      mlUserLoginRequired: {
        moduleId: this.moduleId,
        key: 'ml_user_login_required',
        value: false,
        queryText: 'user_login_required',
        type: this.type
      },
      mlLinkToAppStore: {
        moduleId: this.moduleId,
        key: 'ml_link_to_app_store',
        value: '',
        queryText: 'link_app_store',
        type: this.type
      },
      mlLinkToPlayStore: {
        moduleId: this.moduleId,
        key: 'ml_link_to_play_store',
        value: '',
        queryText: 'link_play_store',
        type: this.type
      },
    }

  }

  saveQrImage() {
    // @ts-ignore
    const figure = document.querySelector('#qrcode img').getAttribute('src');
    const a = document.createElement('a'); // Create <a>
    // @ts-ignore
    a.href = figure;
    a.download = `${Vue.prototype.translate(this.guestFlowModule.name)}.png`; // File name Here
    a.click(); // Downloaded file
  }

  showUrl() {
    let qrUrl = '';
    let count = 0;
    if (this.guestFlowModule.baseUrls.slice(-1) !== '/') {
      qrUrl += this.guestFlowModule.baseUrls + '/';
    } else {
      qrUrl += this.guestFlowModule.baseUrls;
    }
    if(qrUrl) {
      for (const index in this.qrAttributes) {
        if (index) {
          if (count == 0) {
            qrUrl += routerBase + '?tenantName=' + routerBase + '&' + 'moduleType=guestflow' + '&' + 'moduleId=' + '&' + 'elementId=' + this.moduleId + '&';
          } else {
            qrUrl += '&';
          }
          qrUrl += this.qrAttributes[index].queryText + '=' + this.guestFlowModule.qrAttributes[index].value;
          count++;
        }
      }
      this.urlContent = qrUrl;
      this.guestFlowModule.url = this.urlContent.replace(this.guestFlowModule.baseUrls, "");
      if (this.guestFlowModule.url.charAt(0) !== '/') {
        this.guestFlowModule.url += '/';
      }
    }
  }

  @Watch('guestFlowModule')
  guestFlowModuleWatch() {
    if (!this.guestFlowModule.qrAttributes || this.guestFlowModule.qrAttributes.length <= 0) {
      this.guestFlowModule.qrAttributes = this.qrAttributes;
    }
    if( Reflect.ownKeys(this.guestFlowModule.qrAttributes).length <= 6){
      for (const index in this.qrAttributes) {
        if (!this.guestFlowModule.qrAttributes[index]) {
          this.guestFlowModule.qrAttributes[index] = this.qrAttributes[index];
        }
      }
    }
    if (this.guestFlowModule.url) {
      this.showUrl();
    }
  }

  @Watch('moduleId')
  moduleIdWatch() {
    for (const index in this.qrAttributes) {
      if (index) {
        this.qrAttributes[index].moduleId = this.moduleId;
      }
    }
  }

  @Watch('type')
  typeWatch() {
    for (const index in this.qrAttributes) {
      if (index) {
        this.qrAttributes[index].type = this.type;
      }
    }
  }
}
